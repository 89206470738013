import { ImageValidator } from '~/validators'
import HotVideoModal from '~/components/Molecules/VideoModal/VideoModal.vue'

export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    avatar: {
      type: Object,
      validator: prop => ImageValidator(prop, 'avatar'),
    },

    body: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    company: {
      type: String,
    },

    type: {
      type: String,
      default: 'default',
      validator: prop => ['default', 'solid', 'magnetic', 'video'].includes(prop),
    },

    theme: {
      type: String,
    },

    tag: {
      type: String,
    },

    icon: {
      type: Object,
    },

    title: {
      type: String,
    },

    background: {
      type: Object,
      validator: prop => prop.hasOwnProperty('src'),
    },

    video: {
      type: Object,
      validator: prop => prop.hasOwnProperty('youtubeId') && prop.hasOwnProperty('length'),
    },
  },

  data() {
    return {
      modal: true,
    }
  },

  methods: {
    play(e) {
      if (this.video) {
        const modalOptions = {
          component: HotVideoModal,
          data: {
            youtubeId: this.video.youtubeId,
            aspectRatio: '16:9',
          },
        }

        this.$modal.open(modalOptions)
      }
    },
  },
}
