export default {
  props: {
    format: {
      type: String,
      default: 'text',
      validator: prop => ['text', 'pill'].includes(prop),
    },

    theme: {
      type: String,
      default: 'dark',
      validator: prop => ['light', 'dark', 'custom'].includes(prop),
    },
  },
}
